import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    focusOnJapan: Core.ImageAttributes;
    bequestBenefit: Core.ImageAttributes;
    investingInFuture: Core.ImageAttributes;
    womenLeaders: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    focusOnJapan,
    bequestBenefit,
    investingInFuture,
    womenLeaders
  } = props.data;

  return (
    <Layout departmentSlug="philanthropy" title="Philanthropy">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Philanthropy</Core.DepartmentLabel>
        <Core.Flag>Shape the World</Core.Flag>
        <Core.ContentSection id="matchmaker-matchmaker">
          <Core.PrimaryHeading>Matchmaker, Matchmaker</Core.PrimaryHeading>
          <p>
            <Core.BoldText>
              The 75th anniversary of SAIS offers a wonderful opportunity for
              alumni to celebrate the school’s rich history—and to invest in its
              future by increasing student aid, one of the top priorities of
              SAIS’ anniversary fundraising campaign.
            </Core.BoldText>
          </p>
          <p>
            <Core.BoldText>
              Among peer institutions, SAIS ranks the lowest in endowment per
              student, leaving the school with less financial aid for the
              applicant pool. As a result, each year SAIS loses many top
              candidates to schools offering more generous financial aid
              packages.
            </Core.BoldText>
          </p>
          <p>
            <Core.BoldText>
              Fortunately, a number of generous alumni have stepped up to commit
              matching funds toward a variety of{' '}
              <a href="#christian-herter-society">giving opportunities</a> as
              part of the school’s 75th anniversary Shape the World campaign.
            </Core.BoldText>
          </p>
          <p>
            <Core.BoldText>
              “Their matching commitments give other alumni an opportunity to
              dramatically increase the impact of their gifts,” notes Kimberle
              Morton, associate dean for development and alumni relations at
              SAIS.
            </Core.BoldText>
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.HorizontalRule compact />
          <Core.SecondaryHeading>A Focus on Japan</Core.SecondaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={focusOnJapan}
                alt="two men bowing"
                contentSectionPlacement="marginalia"
              />
              <Core.Blockquote
                color="red"
                author="Gary Talarico"
                authorTitle=" ’83"
              >
                Ben was an amazing mentor to me. His value and contribution to
                our Japanese business was immeasurable.
              </Core.Blockquote>
            </>
          }
        >
          <p>
            Alumni Gary Talarico ’83 and Linda Filardi ’83 were inspired to
            create a fellowship in honor of Benjamin Moore Rowland, PhD ’75, who
            recruited Talarico to join Lehman Brothers in the early 1980s to
            focus on developing investment banking opportunities with Japan.
          </p>
          <p>
            At the time, Lehman Brothers was searching for an American graduate
            student who was fluent in Japanese, with business experience in that
            country. Talarico, a newly minted SAIS graduate, fit the bill
            perfectly: He subsequently spent 15 years at Lehman Brothers focused
            on Japan, and his entire career in investment banking and private
            equity has been involved with that country. Throughout his years at
            Lehman Brothers, Talarico says, Rowland remained a trusted
            colleague.
          </p>
          <p>
            “Ben was an amazing mentor to me,” says Talarico, today managing
            partner of Cora Street Partners and an adjunct professor at SAIS.
            “His value and contribution to our Japanese business was
            immeasurable. He was so respected by his Japanese clients, as he
            invested so much time and effort in developing relationships of
            trust. It was from him that I learned the value of providing honest
            advice to clients even when it meant telling them not to do
            something that would make us money.”
          </p>
          <p>
            With the{' '}
            <Core.BoldText>Benjamin Moore Rowland Fellowship</Core.BoldText>,
            Talarico and Filardi, senior director and associate general counsel
            at Capital One Bank, aim to support the work of future SAIS students
            whose interests are focused on Japan.
          </p>
          <p>
            “Now more than ever,” notes Talarico, “the U.S.-Japan relationship
            is the most important bilateral relationship in Asia for the
            security and prosperity of the world.”
          </p>
          <p>
            The couple’s matching gift means that alumni who want to support
            this fellowship will see the value of their gift doubled.
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.HorizontalRule compact />
          <Core.SecondaryHeading>
            ‘Down Payment’ on Future Philanthropy
          </Core.SecondaryHeading>
          <p>
            If you’re a SAIS alum who graduated during the 2000s, Todd and
            Christine Fisher want to offer a way for you to make a “down
            payment” now toward a permanently named fellowship fund in the
            future.
          </p>
          <p>
            &ldquo;With the Future Philanthropist Fund, we’re challenging fellow
            alumni to make a gift of $5,000 per year for five years, which we
            will match 1:1,” explains Todd ’91, chair of SAIS’ board of
            advisors.&rdquo;
          </p>
          <p>
            The Fishers are aiming to entice 20 alumni to commit to this
            challenge as Todd was enticed by a similar Bloomberg Challenge over
            20 years ago. “The money would be placed in a pooled incubator fund
            and donors would get information on financial aid recipients and the
            chance to engage with the students directly. Over time these gifts
            would result in nearly $40,000 per year in new financial aid for
            students,” Todd notes.
          </p>
          <p>
            But it doesn’t end there. The Fishers are hoping that donors will
            continue giving even after completing the initial $25,000 “down
            payment.” Once you contribute an additional $50,000 to the Future
            Philanthropist Fund, a new endowed account will be established in
            your name.
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.HorizontalRule compact />
          <Core.SecondaryHeading>
            A Bequest With Immediate Benefit
          </Core.SecondaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={bequestBenefit}
              alt="hand holding wrapped gift"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <p>
            Gifts made in the form of a bequest typically aren’t set in motion
            until after a donor passes away. But alumni Peter and Pam Flaherty,
            both members of the SAIS advisory board, have created a way for SAIS
            donors to experience the immediate gratification of making a bequest
            with the Flaherty Bequest Matching Challenge
          </p>
          <p>
            Peter and I both received a fantastic education at SAIS—enriching
            our lives with knowledge, opening a world of opportunities, and
            forging many lifelong friends,” says Pam ’68, former president and
            CEO of Citi Foundation. “We have been supporting SAIS for many years
            and decided this time to structure our gift to encourage others and
            enhance their giving.”
          </p>
          <p>
            Here’s how it works: When you make a bequest commitment for an
            endowed fellowship, a cash gift equal to 5 percent of the face value
            of the bequest commitment (up to a maximum cash gift of $25,000)
            will be directed from the Flaherty Bequest Matching Fund to a
            current use fellowship of your choosing. (So, for example, if you
            document a $100,000 estate gift for an endowed fellowship, a student
            will receive a current use $5,000 fellowship in your name.)
          </p>
          <p>
            “With this strategy, we’ll be able to significantly enhance our
            future endowed fellowship funds while also providing much-needed
            current use cash for fellowships,” says Peter, B ’67, ’68, managing
            director of Arcon Partners and vice chair of TechnoServe, which
            works with people in the developing world to build competitive
            farms, businesses, and industries.
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.HorizontalRule compact />
          <Core.SecondaryHeading>Investing in the Future</Core.SecondaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={investingInFuture}
                alt="man in target with telescope"
                contentSectionPlacement="marginalia"
              />
              <Core.Blockquote color="red">
                SAIS plays a vital role educating the next generation of
                diplomats and business executives, and we are delighted to help
                support the school.
              </Core.Blockquote>
            </>
          }
        >
          <p>
            Mike White ’76 enjoyed a long and successful career in international
            business, as Vice Chair of PepsiCo, CEO of PepsiCo International,
            and CEO of Directv.
          </p>
          <p>
            More than many people, he recognizes the importance of investing in
            financial aid for future students—both now and for years to come.
          </p>
          <p>
            That’s why White has committed matching funds to build a financial
            aid endowment through the Michael White Endowed Scholars program.
            Alumni who pledge to contribute $50,000 over the next five years
            will see their gift matched by White’s program—boosting the overall
            amount to $100,000, which will then be transferred to an endowed
            fund.
          </p>
          <p>
            “SAIS was extraordinarily generous in providing me much-needed
            fellowship support when I was a student,” says White, who now serves
            on the SAIS Advisory Board. “With a wife and infant child, I could
            not have afforded to come to SAIS without that financial aid. Now I
            am delighted we can help today’s students in the same way we were
            helped in the 1970s.
          </p>
          <p>
            “The study of international relations is critically important in an
            increasingly complex and globalized world,” he adds. “SAIS plays a
            vital role educating the next generation of diplomats and business
            executives, and we are delighted to help support the school.”
          </p>
          <p>
            “Through Mike’s vision and generosity,” notes Kimberle Morton, “we
            are creating an opportunity for alumni who might not otherwise be
            able to create an endowed fund to do so.”
          </p>
          <Core.Share id="matchmaker-matchmaker" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <div id="lifting-up-future-women-leaders">
          <Core.PrimaryHeading>
            Lifting Up Future Women Leaders
          </Core.PrimaryHeading>
          <Core.ContentSection>
            <p>
              <Core.LeadIn>Women’s leadership</Core.LeadIn> has been an area of
              strategic focus for SAIS, with thanks due in large part to key
              supporters such as Kathleen “Kathy” Pike B ’91. A member of the
              SAIS Board of Advisors, Pike has been a leading participant in
              efforts at the school to lift up future female global leaders as
              part of the SAIS Women Lead Initiative.
            </p>
            <p>
              Pike’s support of the SWL Initiative helped establish a
              partnership between SAIS and the Asian University for Women, an
              independent, international university in Chittagong, Bangladesh
              that seeks to educate a new generation of women leaders in Asia.
            </p>
            <Core.FullWidthImage
              image={womenLeaders}
              alt="Sabiha Subah Mohona, Kathy Pike, and Samanta Sharmin Laskar"
              caption={
                <>
                  <Core.LeadIn>Left to Right:</Core.LeadIn> Sabiha Subah Mohona,
                  Kathy Pike, and Samanta Sharmin Laskar
                </>
              }
            />
          </Core.ContentSection>
        </div>

        <Core.ContentSection
          marginalia={
            <Core.Blockquote color="blue">
              Through the SWL Initiative, Mohona and Laskar have become able to
              travel to Washington, D.C., and take advantage of opportunities to
              extend their experience beyond the classroom through an
              internship, practicum, and professional mentorship
            </Core.Blockquote>
          }
        >
          <p>
            Under the auspices of SWL, Pike’s funding has enabled SAIS to
            provide scholarship support to two rising stars and AUW Alumnae,
            Sabiha Subah Mohona and Samanta Sharmin Laskar. Mohona and Laskar
            began their SAIS education this past fall with full funding to
            pursue master’s degrees in international studies at SAIS. Both are
            Bangladeshi nationals, and both graduated from AUW with economics
            degrees in 2016.
          </p>

          <p>
            Through the SWL Initiative, Mohona and Laskar have become able to
            travel to Washington, D.C., and take advantage of opportunities to
            extend their experience beyond the classroom through an internship,
            practicum, and professional mentorship. The funding from the SWL
            Initiative allows SAIS to be an extension of the important
            educational experience these students have already completed at AUW,
            where most students receive scholarships to obtain degrees to which
            they otherwise would not have access.
          </p>
          <p>
            “Programs such as SWL are essential in solidifying the place of
            women as leaders in the global arena, and the SAIS-AUW partnership
            is an exemplar of a valuable bridge between Asia and the U.S.,
            making it possible to forge connections between women from diverse
            regions of the world,” notes Kimberle Morton.
          </p>
          <p>
            She adds, “SAIS is excited to support the SWL Initiative. We are
            grateful for Kathy’s leadership, and we are thrilled to witness the
            growing momentum at SAIS focused on advancing women’s education and
            leadership in global affairs. It is important now more than ever to
            highlight the efforts that our SAIS community undertakes to support
            the future generations of women, understanding the global impact one
            can have when we realize opportunities to minimize the distance
            between our global communities.”
          </p>
          <Core.Share id="lifting-up-future-women-leaders" />
        </Core.ContentSection>
        <Core.HorizontalRule />
        <Core.ContentSection id="christian-herter-society">
          <Core.Flag>Christian Herter Society</Core.Flag>
          <Core.PrimaryHeading>
            Preparing Future Global Leaders
          </Core.PrimaryHeading>
          <p>
            <Core.BoldText>
              In 1943, Christian A. Herter and Paul H. Nitze founded the School
              of Advanced International Studies (SAIS) to address a critical
              need in the post-World War II era: preparing future leaders for
              careers in international affairs taught by those who shaped
              foreign policy. Today, more than 19,000 SAIS alumni in over 120
              countries, including more than 200 current and former ambassadors,
              are fulfilling the vision of Herter and Nitze.
            </Core.BoldText>
          </p>
          <Core.SecondaryHeading>
            The Christian Herter Society
          </Core.SecondaryHeading>
          <p>
            A distinguished diplomat and public official, Herter, along with
            Nitze, provided the visionary leadership that the school required
            during its early years. Now, SAIS needs its alumni to serve as the
            new leaders today and for the future. Please join fellow alumni and
            friends in giving back to SAIS by becoming a member of the Christian
            Herter Society and supporting the school as it continues to provide
            the best education in international affairs.
          </p>
          <br />
          <p>
            Your annual gift of $2,500 or more is critically important in
            helping SAIS to:
          </p>
          <ul>
            <li>Provide necessary financial aid to excellent students.</li>
            <li>Attract and retain outstanding faculty.</li>
            <li>
              Expand and adapt program offerings to meet the challenges of a
              rapidly changing international environment.
            </li>
          </ul>
          <p>
            Benefits of Herter Society membership extend beyond leadership
            alumni status:
          </p>
          <ul>
            <li>Priority invitation status for lectures and events</li>
            <li>Two tickets to Commencement in Washington, D.C.</li>
          </ul>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              <em>
                For more information visit:{' '}
                <a
                  href="http://www.sais-jhu.edu/atoz/christian-herter-society#welcome"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sais-jhu.edu/atoz/christian-herter-society
                </a>
              </em>
            </Core.Caption>
          }
          marginaliaStackedBottom
        >
          <p>
            Annual membership in the Herter Society is based on gifts of $2,500
            or more during a single fiscal year, beginning July 1 and ending
            June 30. Recent alumni are also recognized as members of the
            Christian Herter Society when they make annual gifts of $500/$41.70
            monthly (five to 10 years after graduation) or $250/$20.84 monthly
            (up to five years after graduation).
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.SecondaryHeading>
            The Richard Nijkerk and Jesse Friedlander 75th Anniversary Christian
            Herter Society Challenge
          </Core.SecondaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              <em>
                Please contact Anna Lemberger at{' '}
                <a href="mailto:anna.lemberger@jhu.edu">
                  anna.lemberger@jhu.edu
                </a>{' '}
                for more information.
              </em>
            </Core.Caption>
          }
          marginaliaStackedBottom
        >
          <p>
            During SAIS’ 75th anniversary year, we seek to increase the number
            of Christian Herter Society members from 175 to 200. Make a gift to
            the Fund for SAIS at the $2,500 Christian Herter Society level or
            higher. Your gift will immediately be doubled, and you will be
            recognized in our 75th anniversary gala honor roll at the full
            value.
          </p>
        </Core.ContentSection>
        <Core.Share id="christian-herter-society" />
        <Core.MoreResources>
          <p>
            To learn more about the Christian Herter Society, visit{' '}
            <a
              href="https://www.sais-jhu.edu/atoz/christian-herter-society#welcome"
              target="_blank"
              rel="noopener noreferrer"
            >
              sais-jhu.edu/atoz/christian-herter-society
            </a>
            .
          </p>
          <br />
          <p>
            To learn more about the Richard Nijkerk and Jesse Friedlander 75th
            Anniversary Christian Herter Society Challenge, visit the Giving tab
            on the anniversary website at{' '}
            <a
              href="https://75.sais-jhu.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              75.SAIS-JHU.edu
            </a>
            .
          </p>
          <br />
          <p>
            To make a donation in honor of SAIS’ 75th anniversary, visit{' '}
            <a
              href="https://75.sais-jhu.edu/#giving"
              target="_blank"
              rel="noopener noreferrer"
            >
              75.sais-jhu.edu/#giving
            </a>
          </p>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    focusOnJapan: file(
      relativePath: { regex: $directory }
      name: { eq: "focus-on-japan" }
    ) {
      ...MarginaliaImage
    }
    bequestBenefit: file(
      relativePath: { regex: $directory }
      name: { eq: "bequest-benefit" }
    ) {
      ...MarginaliaImage
    }
    investingInFuture: file(
      relativePath: { regex: $directory }
      name: { eq: "investing-in-the-future" }
    ) {
      ...MarginaliaImage
    }
    womenLeaders: file(
      relativePath: { regex: $directory }
      name: { eq: "women-leaders" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
